<template>
  <iq-card class="px-3 sm-p-0">
    <div class="row pt-4 sm-p-0">
      <div
        class="col-md-6 col-sm-6"
        v-for="(data, index) in directors"
        :key="index"
      >
        <router-link
          :to="{ name: 'user.profile', params: { userId: data.id } }"
        >
          <div class="iq-card m-3 text-center bg-java">
            <div class="card-body iq-card-body ">
              <div class="user-img img-fluid">
                <img
                  v-if="data && data.avatar"
                  :src="data.avatar"
                  alt="story-img"
                  class="avatar-100 rounded-circle"
                />
                <img
                  v-else
                  src="../../assets/images/user-icon.svg"
                  alt="story-img"
                  class="avatar-100 rounded-circle"
                />
              </div>
              <div class="mt-3 text-white">
                <h4 class="mb-2 text-white">{{ data.name }}</h4>
                <p class="mb-0">
                  {{
                    data.user_data ? JSON.parse(data.user_data).designation : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- <div class="col-md-6 col-sm-6">           
            <div class="iq-card m-3 text-center bg-java">
                <div class="card-body iq-card-body ">
                   <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-100 rounded-circle"></div>
                        <div class="mt-3 text-white">
                          <h4 class="mb-2 text-white">Claire Crossett</h4>
                          <p class="mb-0">Asst. Director</p>
                      </div>
                </div>
               </div>              
          </div> -->
    </div>

    <div class="iq-card-body">
      <h3 class="card-title text-center">Our Team</h3>
      <br />
      <div class="accordion" role="tablist">
        <b-card
          no-body
          class="mb-1"
          v-for="(data, index) in allData"
          :key="index"
        >
          <b-card-header
            header-tag="header"
            class="p-0"
            role="tab"
            v-if="data.pUser.role === 0"
          >
            <b-button block v-b-toggle="'accordion-' + index" variant="info">
              <ul class="m-0 pl-1">
                <li class="d-flex my-2 align-items-center">
                  <div class="user-img img-fluid">
                    <img
                      v-if="data.pUser && data.pUser.avatar"
                      :src="data.pUser.avatar"
                      alt="story-img"
                      class="avatar-80 rounded-circle"
                    />
                    <img
                      v-else
                      src="../../assets/images/user-icon.svg"
                      alt="story-img"
                      class="avatar-80 rounded-circle"
                    />
                  </div>
                  <div class="media-support-info ml-3 text-left">
                    <h4 class="mb-2">{{ data.pUser.name }}</h4>
                    <p class="mb-0 text-black">
                      {{
                        data.pUser.user_data
                          ? JSON.parse(data.pUser.user_data).designation
                          : ""
                      }}

                      <span
                        style="margin-left:5px;color:#70c4da;font-weight:bold"
                        ><router-link
                          :to="{
                            name: 'user.profile',
                            params: { userId: data.pUser.id },
                          }"
                          >View Profile</router-link
                        ></span
                      >
                    </p>
                  </div>
                  <i
                    class="fa fa-angle-down  text-black font-size-26"
                    aria-hidden="true"
                  ></i>
                </li>
              </ul>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="sm-p-0">
              <div class="row">
                <div
                  class="col-md-6 col-sm-6"
                  v-for="child in data.cUsers"
                  :key="child.id"
                >
                  <div class="iq-card m-3">
                    <div class="card-body iq-card-body">
                      <ul class="m-0 p-0">
                        <router-link
                          :to="{
                            name: 'user.profile',
                            params: { userId: child.id },
                          }"
                        >
                          <li class="d-flex align-items-center">
                            <div class="user-img img-fluid">
                              <img
                                v-if="child && child.avatar"
                                :src="child.avatar"
                                alt="story-img"
                                class="avatar-70 rounded-circle"
                              />
                              <img
                                v-else
                                src="../../assets/images/user-icon.svg"
                                alt="story-img"
                                class="avatar-70 rounded-circle"
                              />
                            </div>
                            <div class="media-support-info ml-3">
                              <h5 class="mb-2">{{ child.name }}</h5>
                              <p class="mb-0">
                                {{
                                  child.user_data
                                    ? JSON.parse(child.user_data).designation
                                    : ""
                                }}
                              </p>
                            </div>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Barbara Okeyo</h5>
                          <p class="mb-0">Comms. Officer</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Catherine Osita</h5>
                          <p class="mb-0">Women First Counselor </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Lina López</h5>
                          <p class="mb-0">Spanish/ Portuguese/ English Counselor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Saabira Kwatra</h5>
                          <p class="mb-0">Hindi Counselor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/user-01.22e5a823.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Magdalena Ines Goldin</h5>
                          <p class="mb-0">Spanish Speaking Counselor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Laura Lopez</h5>
                          <p class="mb-0">Illustrator</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Aicha Ka</h5>
                          <p class="mb-0">French Counselor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">María Berenice</h5>
                          <p class="mb-0">Animator</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div> -->
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Rose</h4>
                <p class="mb-0 text-black">Program Manager HTU</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Sneha Nair</h5>
                          <p class="mb-0">HTU Global Comms. Officer</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Paulina Valeria</h5>
                          <p class="mb-0">Full-time Illustrator</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Luisina Mellao</h5>
                          <p class="mb-0">Full-time Animator</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
        </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/06.82085e4a.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Cecilia Gonzalez</h4>
                <p class="mb-0 text-black">Program Manager FMM</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
         <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/user-01.22e5a823.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Varenka Alejandra Bolio Hernández</h5>
                          <p class="mb-0">FMM Designer</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Ferya Ilyas</h5>
                          <p class="mb-0">Comms. Officer</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
        </div>
        </b-card-body>
      </b-collapse>
    </b-card>

     <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-4 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Sonia Naik</h4>
                <p class="mb-0 text-black">Strategic Planning Coordinator</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
        <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/user-01.22e5a823.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Marie-Simone Kadurira</h5>
                          <p class="mb-1">Consultant</p>
                           <p class="mb-1 text-black">Southern Africa & South East Asia </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">EDOUA Hanzet Murielle Marie-Désirée</h5>
                          <p class="mb-1">Consultant</p>
                           <p class="mb-1 text-black">Cote d'Ivoire</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Mrinalini Dayal</h5>
                          <p class="mb-1">Consultant </p>
                            <p class="mb-1 text-black">South Asia</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
       
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Lilian Muchoki</h5>
                          <p class="mb-1">Country Consultant </p>
                          <p class="mb-1 text-black">Kenya</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/user-01.22e5a823.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Ouedraogo Sirina Sompingda</h5>
                          <p class="mb-1">Country Consultant </p>
                          <p class="mb-1 text-black">FWACA</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Alesinloye-king Oluwapelumi</h5>
                           <p class="mb-1">Country Consultant </p>
                          <p class="mb-1 text-black">Nigeria</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Eunice Naffie Mustapha</h5>
                           <p class="mb-1">Country Consultant</p>
                          <p class="mb-1 text-black">Sierra Leone</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Joyce Malu Holt</h5>
                           <p class="mb-1">Web Developer</p>
                          <p class="mb-1 text-black">Liberia</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Daniela Tellez Del Valle</h5>
                           <p class="mb-1">Country Consultant</p>
                          <p class="mb-1 text-black">Spanish </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Melisa Trad Malmod</h5>
                           <p class="mb-1">Country Consultant</p>
                          <p class="mb-1 text-black">Spanish</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Aysima Kiris</h5>
                           <p class="mb-1">Country Consultant </p>
                          <p class="mb-1 text-black"> Turkey</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
       
        </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-5 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/09.b245ce8d.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Michell Mor</h4>
                <p class="mb-0 text-black">Digital Content Officer</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
         <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Vianey Amairani</h5>
                           <p class="mb-1">Social Media Manager </p>
                          <p class="mb-1 text-black">Lorem Inspum</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Christi Lee</h5>
                           <p class="mb-1">Designer</p>
                          <p class="mb-1 text-black">Lorem Inspum</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Swati Saxena</h5>
                           <p class="mb-1">SEO Consultant</p>
                          <p class="mb-1 text-black">Lorem Inspum</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Diana Suárez</h5>
                           <p class="mb-1">Spanish SEO Analyst</p>
                          <p class="mb-1 text-black">Lorem Inspum</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Ofelia Barrón</h5>
                           <p class="mb-1">French SEO Analyst</p>
                          <p class="mb-1 text-black">Lorem Inspum</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
        </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-6 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/02.ee9d5afe.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Nikhil Nevrekar</h4>
                <p class="mb-0 text-black">Tech Manager</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
         <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Feli Cecilia</h5>
                           <p class="mb-1">Web Developer </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Karla Lorena</h5>
                           <p class="mb-1">Web Developer </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Giovanna Priscila Gonzalez</h5>
                           <p class="mb-1">Web Developer </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Jai Bararia</h5>
                           <p class="mb-1">Web Developer </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
           <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Lorem Inspum</h5>
                           <p class="mb-1">Web Developer </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
            <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                          <h5 class="mb-2">Malte</h5>
                           <p class="mb-1">System Admin </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>
        </div>
        </b-card-body>
      </b-collapse>
    </b-card>

      <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-7 variant="info">
        <ul class="m-0 pl-1">
          <li class="d-flex my-2 align-items-center">
            <div class="user-img img-fluid">
              <img src="/img/08.87f544be.jpg" alt="story-img" class="avatar-80 rounded-circle">
              </div><div class="media-support-info ml-3 text-left">
                <h4 class="mb-2">Neha Arora</h4>
                <p class="mb-0 text-black">Manager-Finance & HR</p></div>
                 <i class="fa fa-angle-down  text-black font-size-26" aria-hidden="true"></i>
          </li>
         </ul>
         </b-button>
      </b-card-header>
      <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
         <b-card-body class="sm-p-0">
        <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Sarina Crasto </h5>
                           <p class="mb-1">Finance and HR Assistant </p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>       
        </div>
        </b-card-body>
      </b-collapse>
    </b-card> -->
        <!-- <div class="py-4">
            <h3 class="card-title text-center mb-3">Our Support Team</h3>
            <div class="row">
          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Dr. Kimberly Remski</h5>
                           <p class="mb-0">Medical Advisor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>    

          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">S.V. Kedia & Associates</h5>
                           <p class="mb-0">Accounting Services</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>  

          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">AMP Legal</h5>
                           <p class="mb-0">Legal Advisor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>  

          <div class="col-md-6 col-sm-6">           
              <div class="iq-card m-3">
                <div class="card-body iq-card-body ">
                  <ul class="m-0 p-0">
                    <li class="d-flex align-items-center">
                      <div class="user-img img-fluid">
                        <img src="/img/03.35f64687.jpg" alt="story-img" class="avatar-70 rounded-circle"></div><div class="media-support-info ml-3">
                         <h5 class="mb-2">Julia Riccardi</h5>
                           <p class="mb-0">Copy Editor</p>
                      </div>
                    </li>
                  </ul>
                </div>
               </div>               
          </div>     
        </div>




          </div> -->
        <br />
      </div>
    </div>
  </iq-card>
</template>
<script>
import { socialvue } from "../../config/pluginInit";
import axios from "../../components/axios";

export default {
  name: "Team",
  data() {
    return {
      allData: [],
      directors: [],
    };
  },
  async mounted() {
    socialvue.index();

    var parent = this;
    axios.defaults.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");

    const config = {
      method: "get",
      url: "orgchart",
    };

    await axios(config)
      .then((res) => {
        var finalArr = res.data.allData.filter(function(val) {
          return val.pUser != null;
        });
        console.log(res.data.allData);
        console.log(finalArr);

        parent.allData = finalArr;
        // res.data.news.forEach(element => {
        //     element.img = require('../../../../assets/images/page-img/s4.jpg')
        // })
        // parent.event = res.data.news
      })
      .catch((err) => {
        console.log(err.response);
      });

    const configDir = {
      method: "get",
      url: "directors",
    };

    await axios(configDir)
      .then((res) => {
        parent.directors = res.data.directors;
        // res.data.news.forEach(element => {
        //     element.img = require('../../../../assets/images/page-img/s4.jpg')
        // })
        // parent.event = res.data.news
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  method: {
    modalId(i) {
      return "mE" + i;
    },
  },
};
</script>
